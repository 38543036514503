h1{
  margin: 1em;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 50px; */
}
.section-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.section-tabs button {
  background-color: #007bff;
  color: white;
  /* border: 1px solid #ccc; */
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.container .search-bar input {
  /* background-color: #007bff; */
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 2em;
}

.section-tabs button:hover {
  background-color: #e0e0e0;
}

.section-tabs button.active {
  background-color: #444;
  border-bottom: 2px solid #444;
}

.table-container {
  justify-content: center;
  align-items: center;
  max-width: 80%;
  max-height: 720px;
  overflow: auto;
}

.table-container .table {
  width: auto;
  max-height: 720px;
  overflow: auto;
  border-collapse: collapse;
}

.table-container th, td {
  color: white;
  border: 1px solid gray;
  padding: 8px;
  text-align: left;
}

.table-container td button{
  background-color: gray;
  color: white;
  padding: 0.5px 10px;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.button-container {
  margin: 5px;
}

.button-container button {
  margin: 5px;
}

/* timezoneconvert section start */
.timezoneconvert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timezoneconvert label{
  color: white; 
  text-align: center;
}

.timezoneconvert th{
  color: white; 
  padding: 2em;
}

.timezoneconvert td{
  text-align: center;
}

/* timezoneconvert section end */

