.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found-title {
    font-size: 6em;
    margin: 0.5em;
  }
  
  .not-found-text {
    font-size: 1.5em;
  }
  
  .not-found-link {
    font-size: 1em;
    color: #007bff;
    text-decoration: none;
    margin-top: 20px;
  }
  
  .not-found-link:hover {
    text-decoration: underline;
  }
  