.text-mod-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textarea-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
}

.textarea-container textarea {
  margin: 10px;
  min-height: 300px;
}

.input-container {
  display: flex;
  justify-content: space-between;
  margin: -20px;
  padding: 0px;
  width: 100%;
  max-width: 500px;
}