#nav-wrap-alt ul, #nav-wrap-alt li, #nav-wrap-alt a {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

/* nav-wrap */
#nav-wrap-alt {
  font: 12px 'opensans-bold', sans-serif;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 100;
}
.opaque { background-color: #333; }

/* hide toggle button */
#nav-wrap-alt > a.mobile-btn { display: none; }
  
#nav-wrap-alt .nav-bar img {
  height: 57px;
}

ul#nav-bar {
  min-height: 48px;
  width: 100%;

  /* center align the menu */
  text-align: center;
}

ul#nav-bar .logoblock {
    float: left;
}

ul#nav-bar .linkblock {
    float: right;
}

ul#nav-bar li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
}

ul#nav-bar li a {

/* 8px padding top + 8px padding bottom + 32px line-height = 48px */

  display: inline-block;
  padding-left: 1rem;
  line-height: 32px;
   text-decoration: none;
  text-align: left;
  color: #fff;

   -webkit-transition: color .2s ease-in-out;
   -moz-transition: color .2s ease-in-out;
   -o-transition: color .2s ease-in-out;
   -ms-transition: color .2s ease-in-out;
   transition: color .2s ease-in-out;
}
  
ul#nav-bar li a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 900px) {
    /* nav-wrap */
    #nav-wrap-alt {
        margin: 0 auto;
        z-index: 100;
        left: 0;
        top: 0;
        font: 11px 'opensans-bold', sans-serif;
        letter-spacing: 1.5px;
    }
}

@media only screen and (max-width: 767px) {
    /* mobile navigation
    -------------------------------------------------------------------- */
    #nav-wrap-alt {
    font: 12px 'opensans-bold', sans-serif;
    background: transparent !important;
    letter-spacing: 1.5px;  
    width: auto;
    position: fixed;
    top: 0;
    right: 0;
    }
    
    #nav-wrap-alt > a {
        width: 48px;
        height: 48px;
        text-align: left;
        background-color: #CC5200;
        position: relative;
        border: none;
        float: right;
        font: 0/0 a;
        text-shadow: none;
        color: transparent;
        position: relative;
        top: 0px;
        right: 30px;
    }

    #nav-wrap-alt > a:before,
    #nav-wrap-alt > a:after {
        position: absolute;
        border: 2px solid #fff;
        top: 35%;
        left: 25%;
        right: 25%;
        content: '';
    }

    #nav-wrap-alt > a:after { 
        top: 60%; 
    }

    /* toggle buttons */
    #nav-wrap-alt:not( :target ) > a:first-of-type,
    #nav-wrap-alt:target > a:last-of-type  {
        display: block;
    }

    /* hide menu panel */
    #nav-wrap-alt ul#nav-bar {
        width: 80%;
        height: auto;
        display: none;
        clear: both;
        float: right;
        position: relative;
        top: 12px;
        right: 0;
    }

    /* display menu panels */
    #nav-wrap-alt:target > ul#nav-bar	{
        display: block;
        padding: 30px 20px 48px 20px;
        background: #1f2024;
        margin: 0 30px;
        clear: both;
    }

    ul#nav-bar li {
       display: block;
       height: auto;      
       margin: 0 auto; 
       padding: 0 4%;           
       text-align: left;
       border-bottom: 1px solid #2D2E34;
       border-bottom-style: dotted; 
    }
   
    ul#nav-bar li a {  
       display: block;    
       margin: 0;
       padding: 0;      
       margin: 12px 0; 
       line-height: 16px; /* reset line-height from 48px */
       border: none;
    }  
 
}


@media only screen and (max-width: 480px) {
    #nav-wrap-alt ul#nav-bar { 
        /* width: auto;  */
        float: none; 
    }
}