h1 {
  color: #ffffff;
  margin: 0 auto;
  width: 400px;
  text-align: center;
}

h3 {
  color: #ffffff;
  margin: 0 auto;
  width: 400px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  /* text-align: center; */
}

.container button {
  position: relative;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  text-align: center;
}

.container ul {
  margin: 1em auto;
  width: 400px;
}

.container .middle {
  margin: 1em auto;
  width: 400px;
  text-align: center;
}